export enum Path {
  Home = "/",
  Cars = "/auto/",
  CarsCategory = "/auto/:category",
  ModelAuto = "/auto/:category/:carModel",
  Dealer = "/dealers/",
  DealerDetail = "/dealers/:dealerId",
  MirShineray = "/shineray-world/",
  AboutCompany = "/shineray-world/about/",
  AboutBelarus = "/shineray-world/representative-office/",
  LegalInformation = "/shineray-world/legal-information/",
  InfoElement = ":infoId/:infoLabel/",
  Contacts = "/contacts-info/",
  BecomeDealer = "/become-dealer/",
  News = "/news/",
  NewsDetails = "/news/:newsId",
  Owners = "/for-owners/",
  Warranty = "/for-owners/warranty/",
  TechSupport = "/for-owners/tech-support/",
  TechDocs = "/for-owners/tech-documentation/",
  PDF = "/pdf/uploads/:directory/:category/:model/:docType/:docName",
}
